
import {defineStore} from "pinia";

export const useThemeStore = defineStore('theme', {
    state: () => ({
        theme: localStorage?.getItem('theme') ?? 'light'
    }),
    actions: {
        set(theme) {
            document.documentElement.setAttribute('data-bs-theme', theme)
            localStorage.setItem('theme', theme);
            this.theme = theme;
        }
    }
});

