<template>
    <Head>
        <link rel="icon" type="image/svg+xml" :href="prismaFavicon"/>
    </Head>
</template>

<script setup>
import {onMounted} from "vue";
import {Head} from '@inertiajs/vue3';
import {router} from "@inertiajs/vue3";
import {useThemeStore} from "@/Shared/Stores/ThemeStore";
import prismaFavicon from "#/favicon-Prisma-Beveiliging.svg";

let tooltipList;
let popoverList;

// Initialize tooltips on first visit
onMounted(() => {
    initializeTooltips();
});

// Dispose and re-initialize tooltips so the ones from the previous page don't stick around
router.on('navigate', () => {
    initializeTooltips();
});
router.on('finish', () => {
    initializeTooltips();
});

function initializeTooltips() {
    tooltipList?.forEach(tooltip => {
        tooltip.dispose();
    });
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => {
        return new bootstrap.Tooltip(tooltipTriggerEl, {});
    });

    popoverList?.forEach(popover => {
        popover.dispose();
    });
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
            html: true
        })
    })
}



// Initialize theme
useThemeStore().set(useThemeStore().theme);
</script>
