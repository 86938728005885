<template>
    <button v-if="themeStore.theme === 'light'" class="btn btn-sm btn-link" @click="themeStore.set('dark')">
        <font-awesome-icon icon="fa-solid fa-sun" fixed-width style="color: darkorange"/>
    </button>
    <button v-else class="btn btn-sm btn-link" @click="themeStore.set('light')">
        <font-awesome-icon icon="fa-solid fa-moon" fixed-width style="color: lightskyblue"/>
    </button>
</template>

<script setup>
import {useThemeStore} from "@/Shared/Stores/ThemeStore";

const themeStore = useThemeStore();
</script>
