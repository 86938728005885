<template>
    <Teleport to="#toastContainer">
        <div :id="id" class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    <font-awesome-layers class="me-3">
                        <font-awesome-icon icon="fa-solid fa-square" fixed-width size="lg" transform="grow-10"
                                           :style="{color: backgroundColor}"/>
                        <font-awesome-icon :icon="icon" fixed-width size="lg" transform="shrink-4"
                                           :style="{color: iconColor}"/>
                    </font-awesome-layers>

                    {{ message }}
                </div>

                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import {onMounted} from "vue";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";

let instance;

const id = 'toast' + Math.floor(Math.random() * 10000).toString();
const props = defineProps({
    message: String,
    style: String,
});
const emit = defineEmits(['hidden']);

let icon;
let iconColor;
let backgroundColor;
switch (props.style) {
    case 'created':
        icon = 'fa-solid fa-plus';
        iconColor = '#FFFFFF';
        backgroundColor = 'var(--bs-green)';
        break;
    case 'updated':
        icon = 'fa-solid fa-pencil-alt';
        iconColor = '#FFFFFF';
        backgroundColor = 'var(--bs-blue)';
        break;
    case 'deleted':
        icon = 'fa-solid fa-trash';
        iconColor = '#FFFFFF';
        backgroundColor = 'var(--bs-red)';
        break;
    case 'check':
        icon = 'fa-solid fa-check';
        iconColor = '#FFFFFF';
        backgroundColor = 'var(--bs-green)'
        break;
    case 'error':
        icon = 'fa-solid fa-exclamation'
        iconColor = '#000000';
        backgroundColor = 'var(--bs-yellow)';
        break;
    default:
        icon = 'fa-solid fa-info'
        iconColor = '#000000';
        backgroundColor = 'var(--bs-cyan)';
        break;
}

onMounted(() => {
    const toastEl = document.getElementById(id);
    instance = new bootstrap.Toast(toastEl);
    instance.show();

    toastEl.addEventListener('hidden.bs.toast', () => {
        emit('hidden');
    })
});
</script>

<style scoped>

</style>
