import {createApp} from 'vue';
import Toast from "@/Shared/Toast.vue";

/**
 * Maak een nieuw toast-bericht aan
 * @param {string} message
 * @param {string || 'created' || 'updated' || 'deleted' || 'check' || 'error'} [style]
 */
export const newToast = (message, style) => {
    const mountEl = document.createElement('div');
    document.body.appendChild(mountEl);

    let toast = createApp(Toast, {
        message: message,
        style: style,
        onHidden() {
            document.body.removeChild(mountEl);
            toast.unmount();
            toast = null;
        }
    });

    toast.mount(mountEl);
}
